function getDomain(hasDashboard = 'yes'){
    let domainName =''
    let hostName = window.location.hostname
    if( hostName == 'localhost' ){
        domainName = 'https://dev.championsacademy.ca/api/dashboard/'
    }else if( hostName == 'devdash.championsacademy.ca' ){
        domainName = 'https://dev.championsacademy.ca/api/dashboard/'
    }
    else if( hostName == 'testdashboard.championsacademy.ca' ){
        domainName = 'https://test.championsacademy.ca/api/dashboard/'
    }
    else if( hostName == 'dash.championsacademy.ca' || hostName == 'dashboard.championsacademy.ca' ){
        domainName = 'https://main.championsacademy.ca/api/dashboard/'
    }
    else{
        domainName = 'https://live.nurabi.live/api/dashboard/'
    }
    if(hasDashboard == 'no' ){
        domainName = domainName.slice(0 , -10);
    }
    
    
    
    return domainName
}

export default getDomain

